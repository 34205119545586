import {Container} from "../../Container";
import { Draggable } from "react-drag-reorder";
import {useEffect, useState} from "react";
import axios from "axios";
export const ChangePositions = () => {
    const [places, setPlaces] = useState([]);

    useEffect(() => {
        axios.get("https://backend.sorpa.com/places/admin/place_by_position/").then(
            res => {
                setPlaces(res.data);
            }
        ).catch(
            err => {
                console.log(err.response?.data?.message);
            }
        )
    }, []);

    return(
        <div style={{padding:20}}>
            <div style={{display:"flex", flexDirection:"row", gap:32}}>
                <Container/>
                {/*{*/}
                {/*    places.length > 0 &&*/}
                {/*    <div>*/}
                {/*        <h2 style={{color:'white', fontWeight:"bold"}}>Заведения</h2>*/}
                {/*        <Draggable onPosChange={(currentPos, newPos) => {*/}
                {/*            axios.post("https://backend.sorpa.com/places/admin/change_place_position/", {*/}
                {/*                new_order: newPos,*/}
                {/*                old_order: currentPos*/}
                {/*            }).then(*/}
                {/*                res => {*/}
                {/*                }*/}
                {/*            ).catch(*/}
                {/*                err => {*/}
                {/*                    console.log(err.response?.data?.message);*/}
                {/*                }*/}
                {/*            )*/}
                {/*        }}>*/}
                {/*            {places.map((place, idx) => {*/}
                {/*                return (*/}
                {/*                    <div key={idx} className="flex-item" style={{color:"white", padding:10, fontSize: 18}}>*/}
                {/*                        {place}*/}
                {/*                    </div>*/}
                {/*                );*/}
                {/*            })}*/}
                {/*        </Draggable>*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
        </div>
    )
}
