import update from 'immutability-helper'
import {useCallback, useEffect, useState} from 'react'
import { Card } from './Card.js'
import {Button} from "@nextui-org/react";
import axios from "axios";
const style = {
    width: 400,
}
export const Container = () => {
    {
        const [cards, setCards] = useState([
        ])

        useEffect(() => {
            axios.get("https://backend.sorpa.com/places/admin/place_by_position/").then(
                res => {
                    console.log(res.data);
                    setCards(res.data);
                }
            ).catch(
                err => {
                    console.log(err.response?.data?.message);
                }
            )
        }, []);

        const moveCard = useCallback((dragIndex, hoverIndex) => {
            setCards((prevCards) =>
                update(prevCards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevCards[dragIndex]],
                    ],
                }),
            )
        }, [])
        const renderCard = useCallback((card, index) => {
            return (
                <Card
                    key={card.id}
                    index={index}
                    id={card.id}
                    text={card.text}
                    moveCard={moveCard}
                />
            )
        }, [])
        return (
            <div style={{display:"flex", flexDirection:"column", gap: 16}}>
                <Button onClick={() => {axios.post("https://backend.sorpa.com/places/admin/change_place_position/", {places: cards}).then(
                    res => {
                    }
                ).catch(
                    err => {
                        console.log(err.response?.data?.message);
                    }
                )}}>Сохранить изменения</Button>
                <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
            </div>
        )
    }
}
