import {Modal, ModalContent, ModalHeader, ModalBody, Button, Input, user} from "@nextui-org/react";
import {useContext, useEffect, useState} from "react";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {useNavigate} from "react-router";
import axios from "axios";
import AuthContext from "../../context/authContext";

export const UsersTopNav = () => {
    const [userModal, setUserModal] = useState(false);

    const [error, setError] = useState("");

    const [newUser, setNewUser] = useState(
        {
            name: "",
            phone_number: "",
        }
    )

    const submitUser = () => {
        axios.post("https://backend.sorpa.com/users/create/", {...newUser}).then(
            res => {
                window.location.reload()
            }
        ).catch(
            err => {
                setError(err.response?.data?.message)
            }
        )
    }

    return (
        <div style={{height: 72, display: "flex", alignItems: "center", boxSizing: "border-box", padding: 16, gap: 16}}>
            <Button
                onClick={() => {
                    setUserModal(true)
                }}
                style={{
                    width: 240,
                    border: "1px solid #BDFF00",
                    background: "none",
                    color: "#BDFF00",
                    fontWeight: 600,
                    borderRadius: 16
                }}
            >
                <p>Добавить пользователя</p>
            </Button>
            {/*модалки*/}
            <Modal isOpen={userModal} style={{background: "#121212"}} hideCloseButton={true}>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingBottom: 0,
                        paddingRight: 16
                    }}>
                        <h2 style={{color: "white"}}>Добавление пользователя</h2>
                        <Button onClick={() => {
                            setUserModal(false)
                        }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                    </ModalHeader>
                    <ModalBody>
                        {
                            error && <p style={{color: "red"}}>{error}</p>
                        }
                        <Input
                            type={"text"}
                            label="Имя"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            onChange={(event) => {
                                setNewUser({...newUser, name: event.target.value});
                                setError("");
                            }}
                        />
                        <Input
                            type={"tel"}
                            label="Номер телефона"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            onChange={(event) => {
                                setNewUser({...newUser, phone_number: event.target.value});
                                setError("");
                            }}
                        />
                        <Button style={{
                            background: "#BF0",
                            color: "black",
                            fontSize: 16,
                            fontWeight: 600
                        }}
                                onClick={submitUser}
                        >
                            <p>Добавить</p>
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}
