import {EmployersTopNav} from "./EmployersTopNav";
import {
    Button,
    Card,
    Divider,
    Input,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Tooltip
} from "@nextui-org/react";
import {Select, SelectItem} from "@nextui-org/react";
import {SearchIcon} from "../../assets/icons/SearchIcon";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/authContext";
import {useNavigate} from "react-router";
import {EditIcon} from "../../assets/icons/EditIcon";
import {DeleteIcon} from "../../assets/icons/DeleteIcon";
import axios from "axios";

const Employers = () => {
    const history = useNavigate();
    const [city, setCity] = useState(null);
    const [search, setSearch] = useState('');
    const [name, setName] = useState('');
    const [category, setCategory] = useState(null);
    const [places, setPlaces] = useState([]);
    const {filterCities, filterCategories} = useContext(AuthContext);
    const [existSearch, setExistSearch] = useState("");
    useEffect(() => {
        let searchParams = `${city || search || category ? "?" : ''}${city ? `city_id=${city}` : ''}${search ? `&search=${search}` : ''}${category ? `&category_id=${category}` : ''}`
        history(searchParams);
        setExistSearch(searchParams);
        axios.get(`https://backend.sorpa.com/places/admin/list/${searchParams}`).then(
            res => {
                setPlaces(res.data);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }, [city, search, category]);
    const submitSearch = () => {
        setSearch(name);
    }
    useEffect(() => {
        axios.get('https://backend.sorpa.com/places/admin/list/').then(
            res => {
                setPlaces(res.data);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }, []);
    return (
        <>
            <EmployersTopNav/>
            <div style={{
                color: "white",
                width: "100%",
                height: "calc(100vh - 72px)",
                boxSizing: "border-box",
                paddingRight: 16,
                paddingLeft: 16,
                paddingBottom: 16
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    background: "black",
                    maxWidth: 1300,
                    height: "100%"
                }}>
                    <Card style={{width: "100%", background: "#121212", padding: 24}}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            gap: 16
                        }}>
                            <p style={{color: "white", fontWeight: "bold"}}>{places.length} предприятий</p>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 24,
                                alignItems: "center"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    gap: 4,
                                    alignItems: "center"
                                }}>
                                    <div style={{width: 240}}>
                                        <Input
                                            startContent={<SearchIcon/>}
                                            type={"email"}
                                            size={"sm"}
                                            radius="lg"
                                            style={{color: "white"}}
                                            className={"input-text-color"}
                                            classNames={{
                                                label: "input-text-color",
                                                input: "text-white/90",
                                                innerWrapper: "input-text-color",
                                                inputWrapper: "input-main",
                                            }}
                                            placeholder={"Поиск"}
                                            onChange={(event) => {
                                                setName(event.target.value);
                                                if (event.target.value === '') {
                                                    setSearch('');
                                                    setName('');
                                                }
                                            }}
                                        />
                                    </div>
                                    <Button style={{
                                        height: "44px",
                                        background: "#BF0",
                                        color: "black",
                                        fontWeight: 600
                                    }}
                                            onClick={submitSearch}
                                    >
                                        <p>Найти</p>
                                    </Button>
                                </div>
                                <div style={{width: 240}}>
                                    <Select
                                        placeholder={"Все города"}
                                        value={city}
                                        onChange={(items) => {
                                            setCity(items.target.value);
                                            if (!items.target.value || items.target.value === "0") {
                                                setCity(null);
                                            }
                                        }}
                                        listboxProps={{
                                            itemClasses: {
                                                base: "wrapper-content"
                                            },
                                        }}
                                        popoverProps={{
                                            classNames: {
                                                content: "wrapper",
                                            },
                                        }}
                                        disallowEmptySelection={false}
                                        color={"secondary"}
                                        size={"sm"}
                                        style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                        classNames={{
                                            label: "text-white",
                                        }}
                                    >
                                        {filterCities.map((city) => (
                                            <SelectItem key={city.id} value={city.name}>
                                                {city.name}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{width: 240}}>
                                    <Select
                                        placeholder={"Все категории"}
                                        value={category}
                                        onChange={(items) => {
                                            setCategory(items.target.value);
                                            if (!items.target.value || items.target.value === "0") {
                                                setCategory(null);
                                            }
                                        }}
                                        listboxProps={{
                                            itemClasses: {
                                                base: "wrapper-content"
                                            },
                                        }}
                                        popoverProps={{
                                            classNames: {
                                                content: "wrapper",
                                            },
                                        }}
                                        disallowEmptySelection={false}
                                        color={"secondary"}
                                        size={"sm"}
                                        style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                        classNames={{
                                            label: "text-white",
                                        }}
                                    >
                                        {filterCategories.map((category) => (
                                            <SelectItem key={category.id} value={category.name}>
                                                {category.name}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{width: 240}}>
                                    <a href={"/employers/change_positions"} style={{color:"white", fontWeight:900}}>
                                        Изменить порядок
                                    </a>
                                </div>
                            </div>
                            <Divider style={{background: "rgba(84, 84, 88, 0.65)"}}/>
                            <div>
                                {
                                    places.length === 0 ?
                                        <div style={{
                                            width: "100%",
                                            height: "calc(100vh - 250px)",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <p style={{textAlign: "center", fontWeight: "bold", color: "white"}}>По
                                                вашему запросу ничего не найдено</p>
                                        </div> :
                                        <Table isStriped isHeaderSticky aria-label="Example static collection table"
                                               classNames={{
                                                   base: 'table-wrapper',
                                                   table: "table",
                                                   wrapper: "base-wrapper"
                                               }}
                                        >
                                            <TableHeader>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Наименование</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Город</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Филиалы</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Категория</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Скидка</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    textAlign: "center"
                                                }}>Действия</TableColumn>
                                            </TableHeader>
                                            <TableBody>
                                                {
                                                    places.map((place, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell><p style={{
                                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                    margin: 0
                                                                }}>{place.name}</p></TableCell>
                                                                <TableCell><p style={{
                                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                    margin: 0
                                                                }}>{place.city}</p></TableCell>
                                                                <TableCell><p style={{
                                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                    margin: 0
                                                                }}>{place.branch_count}</p></TableCell>
                                                                <TableCell><p style={{
                                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                    margin: 0
                                                                }}>{place.category}</p></TableCell>
                                                                <TableCell><p style={{
                                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                    margin: 0
                                                                }}>{place.off_percent}</p></TableCell>
                                                                <TableCell style={{width: 120}}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}>
                                                                        <Tooltip content="Редактировать">
                                                                            <Button isIconOnly={true}
                                                                                    onClick={() => {history(`/employers/${place.id}`)}}
                                                                                    style={{background: "none"}}>
                                                                                <EditIcon/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                        <Tooltip content="Удалить">
                                                                            <Button onClick={() => {
                                                                                axios.get(`https://backend.sorpa.com/places/admin/${place.id}/delete/`).then(
                                                                                    res => {
                                                                                        console.log(res);
                                                                                        axios.get(`https://backend.sorpa.com/places/admin/list/${existSearch}`).then(
                                                                                            res => {
                                                                                                setPlaces(res.data);
                                                                                            }
                                                                                        ).catch(
                                                                                            err => {
                                                                                                console.log(err);
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                ).catch(
                                                                                    err => {
                                                                                        console.log(err)
                                                                                    }
                                                                                )
                                                                            }} isIconOnly={true}
                                                                                    style={{background: "none"}}>
                                                                                <DeleteIcon/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                }
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default Employers;
