import {
    Button,
    Card,
    Divider,
    Input,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Tooltip, ModalContent, ModalHeader, ModalBody, Textarea, Modal
} from "@nextui-org/react";
import {Select, SelectItem} from "@nextui-org/react";
import {SearchIcon} from "../../assets/icons/SearchIcon";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/authContext";
import {useNavigate} from "react-router";
import {EditIcon} from "../../assets/icons/EditIcon";
import {DeleteIcon} from "../../assets/icons/DeleteIcon";
import axios from "axios";
import {BlockUser} from "../../assets/icons/BlockUser";
import {WalletIcon} from "../../assets/icons/WalletIcon";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {AddIcon} from "../../assets/icons/AddIcon";

const Club = () => {
    const history = useNavigate();
    const [search, setSearch] = useState('');
    const [name, setName] = useState('');
    const [users, setUsers] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [status, setStatus] = useState('');
    const [existSearch, setExistSearch] = useState("");
    const [addBalance, setAddBalance] = useState(false);
    const [fillSum, setFillSum] = useState(0);
    const [fillId, setFillId] = useState(null);
    const [error, setError] = useState("");
    useEffect(() => {
        let searchParams = `${search || status ? "?" : ''}${search ? `&search=${search}` : ''}${status ? `&user_status=${status}` : ''}`
        history(searchParams);
        setExistSearch(searchParams);
        axios.post(`https://backend.sorpa.com/users/list/${searchParams}`).then(
            res => {
                setUsers(res.data);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }, [search, status]);
    useEffect(() => {
        axios.get('https://backend.sorpa.com/users/list/').then(
            res => {
                setUsers(res.data.users);
                setStatuses(res.data.statuses);
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }, []);
    const submitFill = () => {
        axios.post(`https://backend.sorpa.com/users/${fillId}/fill/`, {fill_sum: fillSum}).then(
            res => {
                setUsers(res.data);
                setName("");
                setFillId(null);
                setFillSum(0);
                setAddBalance(false);
            }).catch(
            err => {
                console.log(err);
            }
        )
    }
    return (
        <>
            <div style={{
                color: "white",
                width: "100%",
                height: "calc(100vh - 72px)",
                boxSizing: "border-box",
                paddingRight: 16,
                paddingLeft: 16,
                paddingBottom: 16,
                marginTop:20
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    background: "black",
                    maxWidth: 1300,
                    height: "100%"
                }}>
                    <Card style={{width: "100%", background: "#121212", padding: 24}}>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            gap: 16
                        }}>
                            <div
                                // onClick={() => {setNewArticle(true)}}
                                style={{
                                    maxWidth:220,
                                    background: "none",
                                    color: "white",
                                    fontWeight: 600,
                                    display:"flex",
                                    gap: 8,
                                    cursor:"pointer"
                                }}
                            >
                                <AddIcon/>
                                <p>Добавить члена клуба</p>
                            </div>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 24,
                                alignItems: "center"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    gap: 4,
                                    alignItems: "center"
                                }}>
                                    <div style={{width: 240}}>
                                        <Input
                                            startContent={<SearchIcon/>}
                                            type={"email"}
                                            size={"sm"}
                                            radius="lg"
                                            style={{color: "white"}}
                                            className={"input-text-color"}
                                            classNames={{
                                                label: "input-text-color",
                                                input: "text-white/90",
                                                innerWrapper: "input-text-color",
                                                inputWrapper: "input-main",
                                            }}
                                            placeholder={"Поиск по номеру"}
                                            onChange={(event) => {
                                                setSearch(event.target.value);
                                                if (event.target.value === '') {
                                                    setSearch('');
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{width: 240}}>
                                    <Select
                                        placeholder={"Все cтатусы"}
                                        value={status}
                                        onChange={(items) => {
                                            setStatus(items.target.value);
                                            if (!items.target.value || items.target.value === "Все статусы") {
                                                setStatus(null);
                                            }
                                        }}
                                        listboxProps={{
                                            itemClasses: {
                                                base: "wrapper-content"
                                            },
                                        }}
                                        popoverProps={{
                                            classNames: {
                                                content: "wrapper",
                                            },
                                        }}
                                        disallowEmptySelection={false}
                                        color={"secondary"}
                                        size={"sm"}
                                        style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                        classNames={{
                                            label: "text-white",
                                        }}
                                    >
                                        {statuses.map((status) => (
                                            <SelectItem key={status.id} value={status.name}>
                                                {status.name}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <Divider style={{background: "rgba(84, 84, 88, 0.65)"}}/>
                            <div>
                                {
                                    users.length === 0 ?
                                        <div style={{
                                            width: "100%",
                                            height: "calc(100vh - 250px)",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <p style={{textAlign: "center", fontWeight: "bold", color: "white"}}>По
                                                вашему запросу ничего не найдено</p>
                                        </div> :
                                        <Table isStriped isHeaderSticky aria-label="Example static collection table"
                                               classNames={{
                                                   base: 'table-wrapper',
                                                   table: "table",
                                                   wrapper: "base-wrapper"
                                               }}
                                        >
                                            <TableHeader>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Наименование</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Номер</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Баланс</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold"
                                                }}>Статус</TableColumn>
                                                <TableColumn style={{
                                                    background: "#525255",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    textAlign: "center"
                                                }}>Действия</TableColumn>
                                            </TableHeader>
                                            <TableBody>
                                                {
                                                    users.map((user, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell><p style={{
                                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                    margin: 0
                                                                }}>{user.name}</p></TableCell>
                                                                <TableCell><p style={{
                                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                    margin: 0
                                                                }}>{user.phone_number}</p></TableCell>
                                                                <TableCell><p style={{
                                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                    margin: 0
                                                                }}>{user.balance}</p></TableCell>
                                                                <TableCell><p style={{
                                                                    borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                                    margin: 0
                                                                }}>{user.user_status}</p></TableCell>
                                                                <TableCell style={{width: 120}}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "center"
                                                                    }}>
                                                                        <Tooltip content="Пополнить баланс">
                                                                            <Button isIconOnly={true}
                                                                                    onClick={() => {setAddBalance(true); setName(user.name); setFillId(user.id);}}
                                                                                    style={{background: "none"}}>
                                                                                <WalletIcon/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                        <Tooltip content="Анулировать баланс">
                                                                            <Button isIconOnly={true}
                                                                                    onClick={() => {axios.get(`https://backend.sorpa.com/users/${user.id}/zero/`).then(
                                                                                        res => {
                                                                                            setUsers(res.data);
                                                                                        }).catch(
                                                                                        err => {
                                                                                            console.log(err);
                                                                                        }
                                                                                    )
                                                                                    }}
                                                                                    style={{background: "none"}}>
                                                                                <EditIcon/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                        <Tooltip content="Заблокировать / Разблокировать">
                                                                            <Button onClick={() => {
                                                                                axios.get(`https://backend.sorpa.com/users/${user.id}/block/unblock/`).then(
                                                                                    res => {
                                                                                        setUsers(res.data);
                                                                                    }).catch(
                                                                                    err => {
                                                                                        console.log(err);
                                                                                    }
                                                                                )
                                                                            }} isIconOnly={true}
                                                                                    style={{background: "none"}}>
                                                                                <BlockUser/>
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                }
                            </div>
                        </div>
                    </Card>
                </div>
                <Modal isOpen={addBalance} style={{background: "#121212"}} hideCloseButton={true}>
                    <ModalContent>
                        <ModalHeader style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingBottom: 0,
                            paddingRight: 16
                        }}>
                            <h2 style={{color: "white"}}>Пополнить баланс {name}</h2>
                            <Button onClick={() => {
                                setAddBalance(false);
                                setName("");
                                setFillId(null);
                            }} isIconOnly={true} style={{background: "none", padding: 0}}><CloseIcon/></Button>
                        </ModalHeader>
                        <ModalBody>
                            {
                                error && <p style={{color: "red"}}>{error}</p>
                            }
                            <div style={{display:"flex" , alignItems:"center", flexDirection:"column", gap:16}}>
                                <Input
                                    type={"number"}
                                    placeholder="Заголовок"
                                    value={fillSum}
                                    size={"sm"}
                                    radius="lg"
                                    style={{color: "white"}}
                                    className={"input-text-color"}
                                    classNames={{
                                        label: "input-text-color",
                                        input: "text-white/90",
                                        innerWrapper: "input-text-color",
                                        inputWrapper: "input-main",
                                    }}
                                    onChange={(event) => {
                                        setFillSum(event.target.value);
                                    }}
                                />
                                <Button style={{
                                    background: "#BF0",
                                    color: "black",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    marginBottom:20,
                                    width:"100%"
                                }}
                                        onClick={submitFill}
                                >
                                    <p>Пополнить</p>
                                </Button>
                            </div>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        </>
    )
}

export default Club;
