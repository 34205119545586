import {Button, Card, Checkbox, Input, Select, SelectItem} from "@nextui-org/react";
import {useNavigate} from "react-router";
import {BackIcon} from "../../assets/icons/BackIcon";
import {AddIcon} from "../../assets/icons/AddIcon";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/authContext";
import axios from "axios";
import {AnchorIcon} from "../../assets/icons/AnchorIcon";

const NewEmployer = () => {
    const headers = {'Content-Type': 'multipart/form-data'};
    const history = useNavigate();
    const [logoPreview, setLogoPreview] = useState(null);
    const [error, setError] = useState("");
    const [employerLogo, setEmployerLogo] = useState(null);
    const [disableLogo, setDisableLogo] = useState(false);
    const [sendData, setSendData] = useState({
        logo_exists: true,
        logo: null,
        name:"",
        head_name:"",
        phone_number:"",
        login:"",
        password:"",
        bin_number:"",
        account_number:"",
        off_percent:"",
        city:"",
        category:"",
        background_image: null,
        agreement: null
    });
    const [city, setCity] = useState(null);
    const [category, setCategory] = useState(null);
    const {filterCities, filterCategories, setFilterCategories, setFilterCities} = useContext(AuthContext);

    useEffect(() => {
        axios.get("https://backend.sorpa.com/properties/admin/list/").then(
            res => {
                let categories = []
                let cities = []
                res.data.sub_categories.map((item) => {
                    if (item.id !== 0) {
                        categories.push(item)
                    }
                })
                res.data.cities.map((item) => {
                    if (item.id !== 0) {
                        cities.push(item)
                    }
                })
                setFilterCategories(categories);
                setFilterCities(cities);
            }
        ).catch(
            err => {
                console.log(err.response?.data?.message);
            }
        )
    }, []);

    const setLogo = (event) => {
        const file = event.target.files[0];
        if (file) {
            setEmployerLogo(file);
            setSendData({...sendData, logo: event.target.files[0]});
            setLogoPreview(URL.createObjectURL(file));
        }
    }
    const submitData = () => {
        console.log(sendData);
        const formData = new FormData();
        formData.append("name", sendData.name);
        formData.append("logo_exists", sendData.logo_exists);
        formData.append("head_name", sendData.head_name);
        formData.append("phone_number", sendData.phone_number);
        formData.append("login", sendData.login);
        formData.append("password", sendData.password);
        formData.append("bin_number", sendData.bin_number);
        formData.append("account_number", sendData.account_number);
        formData.append("off_percent", sendData.off_percent);
        formData.append("city", sendData.city);
        formData.append("category", sendData.category);
        formData.append("agreement", sendData.agreement);
        formData.append("background", sendData.background);
        if (sendData.logo_exists){
            if(employerLogo){
                formData.append("logo", sendData.logo);
            }else{
                formData.append("logo", null);
            }
        }
        console.log(formData);
        axios.post("https://backend.sorpa.com/places/admin/new/", formData, {headers: headers}).then(
            res => {
                console.log(res.data.message);
                history('/employers');
            }
        ).catch(
            err => {
                setError(err.response.data.message);
            }
        )
    }
    return (
        <div style={{
            color: "white",
            width: "100%",
            height: "calc(100vh - 48px)",
            boxSizing: "border-box",
            paddingRight: 16,
            paddingLeft: 16,
            paddingBottom: 16
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                background: "black",
                maxWidth: 800,
                height: "100%",
                marginTop: 32
            }}>
                <div onClick={() => {
                    history("/employers")
                }} style={{
                    color: "white",
                    width: 240,
                    padding: 0,
                    fontSize: 18,
                    background: "none",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    cursor: "pointer"
                }}><BackIcon/>Предприниматели
                </div>
                {/*кнопки*/}
                <div style={{marginTop: 16, display: "flex", gap: 16}}>
                    <Button
                        onClick={submitData}
                        style={{
                            width: 240,
                            border: "1px solid #BDFF00",
                            background: "#BDFF00",
                            color: "black",
                            fontWeight: 600,
                            borderRadius: 16
                        }}
                    >
                        <p>Сохранить</p>
                    </Button>
                    <Button
                        onClick={() => {
                            history("/employers")
                        }}
                        style={{
                            width: 240,
                            border: "1px solid #BDFF00",
                            background: "none",
                            color: "#BDFF00",
                            fontWeight: 600,
                            borderRadius: 16
                        }}
                    >
                        <p>Отмена</p>
                    </Button>
                </div>
                <Card style={{
                    width: "100%",
                    background: "#121212",
                    color: "white",
                    padding: 24,
                    marginTop: 16,
                    display: "flex",
                    flexDirection: "column",
                    gap: 20
                }}>
                    {
                        error &&  <p style={{color:"red"}}>{error}</p>
                    }
                    <div style={{display: "flex", flexDirection: "row", gap: 20, alignItems: "center"}}>
                        <div>
                            {
                                logoPreview ?
                                    <div style={{
                                        width: 78,
                                        height: 78,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <img style={{
                                            borderRadius: 12,
                                            height: 64,
                                            width: 64,
                                            objectFit: "cover",
                                            background: "#2A2A2C"
                                        }} src={logoPreview}/>
                                        <label htmlFor={"logo-input"}><p style={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            marginTop: 4,
                                            textAlign: "center",
                                            cursor: "pointer"
                                        }}>Изменить</p></label>
                                    </div>
                                    :
                                    <label htmlFor={"logo-input"}>
                                        <div style={{
                                            width: 78,
                                            height: 78,
                                            background: "#2A2A2C",
                                            borderRadius: 12,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <AddIcon/>
                                        </div>
                                    </label>
                            }
                            <input accept={"image"} disabled={disableLogo} id={"logo-input"} type={"file"}
                                   style={{display: "none"}}
                                   onChange={(event) => {
                                       setLogo(event);
                                   }}
                            />
                        </div>
                        <div style={{display: "flex", flexDirection: "column", gap: 8}}>
                            <p>Логотип предприятия</p>
                            <Checkbox onChange={(value) => {
                                setDisableLogo(value.target.checked);
                                setError("");
                                setSendData({...sendData, logo_exists: !value.target.checked});
                                if (value.target.checked) {
                                    setLogoPreview(null);
                                }else{
                                    if (employerLogo){
                                        setLogoPreview(URL.createObjectURL(employerLogo));
                                    }
                                }
                            }}><p style={{color: "white"}}>Отсутствует</p></Checkbox>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                        <Input
                            type={"text"}
                            label="Наименование"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.name}
                            onChange={(event) => {
                                setSendData({...sendData, name: event.target.value});
                                setError("");
                            }}
                        />
                        <Input
                            type={"text"}
                            label="Имя Владельца"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.head_name}
                            onChange={(event) => {
                                setSendData({...sendData, head_name: event.target.value});
                                setError("");
                            }}
                        />
                    </div>
                    <div style={{width: "50%", paddingRight: 5}}>
                        <Input
                            type={"tel"}
                            label="Контактный номер владельца"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.phone_number}
                            onChange={(event) => {
                                setSendData({...sendData, phone_number: event.target.value});
                                setError("");
                            }}
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                        <Input
                            type={"text"}
                            label="Логин"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.login}
                            onChange={(event) => {
                                setSendData({...sendData, login: event.target.value});
                                setError("");
                            }}
                        />
                        <Input
                            type={"text"}
                            label="Пароль"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.password}
                            onChange={(event) => {
                                setSendData({...sendData, password: event.target.value});
                                setError("");
                            }}
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                        <Input
                            type={"text"}
                            label="БИН"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.bin_number}
                            onChange={(event) => {
                                setSendData({...sendData, bin_number: event.target.value});
                                setError("");
                            }}
                        />
                        <Input
                            type={"text"}
                            label="Номер счета"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.account_number}
                            onChange={(event) => {
                                setSendData({...sendData, account_number: event.target.value});
                                setError("");
                            }}
                        />
                    </div>
                    <div style={{width: "50%", paddingRight: 5}}>
                        <Input
                            type={"text"}
                            label="Процент скидки"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.off_percent}
                            onChange={(event) => {
                                setSendData({...sendData, off_percent: event.target.value});
                                setError("");
                            }}
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                        <div style={{width: "100%"}}>
                            <Select
                                placeholder={"Выбрать город"}
                                value={city}
                                onChange={(items) => {
                                    setCity(items.target.value);
                                    setSendData({...sendData, city: items.target.value});
                                    if (!items.target.value || items.target.value === "0") {
                                        setCity(null);
                                    }
                                }}
                                listboxProps={{
                                    itemClasses: {
                                        base: "wrapper-content"
                                    },
                                }}
                                popoverProps={{
                                    classNames: {
                                        content: "wrapper",
                                    },
                                }}
                                disallowEmptySelection={false}
                                color={"secondary"}
                                size={"sm"}
                                style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                classNames={{
                                    label: "text-white",
                                }}
                            >
                                {filterCities.map((city) => (
                                    <SelectItem key={city.id} value={city.name}>
                                        {city.name}
                                    </SelectItem>
                                ))}
                            </Select>
                        </div>
                        <div style={{width: "100%"}}>
                            <Select
                                placeholder={"Выбрать категорию"}
                                value={category}
                                onChange={(items) => {
                                    setCategory(items.target.value);
                                    setSendData({...sendData, category: items.target.value});
                                    if (!items.target.value || items.target.value === "0") {
                                        setCategory(null);
                                    }
                                }}
                                listboxProps={{
                                    itemClasses: {
                                        base: "wrapper-content"
                                    },
                                }}
                                popoverProps={{
                                    classNames: {
                                        content: "wrapper",
                                    },
                                }}
                                disallowEmptySelection={false}
                                color={"secondary"}
                                size={"sm"}
                                style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                classNames={{
                                    label: "text-white",
                                }}
                            >
                                {filterCategories.map((category) => (
                                    <SelectItem key={category.id} value={category.name}>
                                        {category.name}
                                    </SelectItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div style={{width: "100%"}}>
                        <label htmlFor={"agreement_file"} style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                            <AnchorIcon/> <p style={{marginLeft:10}}>{sendData.agreement ? 'Договор прикреплен' : 'Прикрепить договор'}</p>
                        </label>
                        <input id={'agreement_file'} style={{display:"none"}} type={"file"} onChange={(event) => {setSendData({...sendData, agreement: event.target.files[0]})}}/>
                    </div>
                    <div style={{width: "100%", marginBottom:20}}>
                        <div style={{width: "100%"}}>
                            <label htmlFor={"background"} style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                <AnchorIcon/> <p style={{marginLeft:10}}>{sendData.background_image ? <a href={`https://backend.sorpa.com${sendData.background_image}`}>Просмотреть фон</a> : 'Прикрепить фон'}</p>
                            </label>
                            <input id={'background'} style={{display:"none"}} type={"file"} onChange={(event) => {setSendData({...sendData, background: event.target.files[0]})}}/>
                        </div>
                    </div>
                </Card>
            </div>
        </div>

    )
}

export default NewEmployer;
